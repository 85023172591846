@import "styles/_variables.scss";

.wrapper {
  font-family: $fontFamily;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 25px;
  padding-bottom: 24px;
  border-bottom: 1px solid $bloghrColor;
  display: flex;
  flex-direction: column;

  &.dark {
    color: white;
  }
}

.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    flex: 1;
  }

  &__icon {
    align-self: center;
    transition: all .2s linear;
    transform: rotate(180deg);

    &.active {
      transform: rotate(0deg);
    }

    &.dark img {
      filter: invert(100%);
    }
  }
}
