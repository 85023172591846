@import "styles/_variables.scss";

.menu {
    &.dark {
        background-color: $black;

        & .button {
            background-color: $white;
            color: $black;
        }
    }

    overflow: scroll;
    visibility: hidden;
    width: 100%;
    background-color: $whiteBackground;
    z-index: 14;
    position: fixed;
    height: 100%;
    transform: translateY(-150%);
    transition: transform .4s linear, visibility .5s linear;

    @media(min-width: $tabletPoint) {
        display: none;
    }

    &__block {
        position: relative;
        min-height: 100%;
        padding: 80px 22px 36px;
    }

    &_active {
        visibility: visible;
        transform: translateY(0%);
        transition: transform .3s linear, visibility .3s linear;
    }

    &__nav {
        height: 100%;

        &__item {
            padding: 24px 0;
            border-bottom: 1px solid #EBEBEB;
        }
    }
}

.item {
    display: flex;
    justify-content: space-between;

    &_arrow {
        position: relative;
        width: 16px;
        height: 16px;
        transition: transform .2s linear;
        transform: rotate(180deg);

        &_active {
            transition: transform .2s linear;
            transform: rotate(0deg);
        }

        img {
            object-fit: contain;
        }
    }
}

.list {
    &__item {
        display: flex;
        margin-top: 20px;
        align-items: center;

        &__image {
            margin-right: 8px;
        }
    }
}

.button {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    background: $greenBackground;
    height: 40px;
    color: $white;
    font-family: $fontFamily;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    transition: opacity .3s;
    width: 100%;

    &:hover {
        opacity: .8;
    }
}
